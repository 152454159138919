import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Roboto', sans-serif",
        heading: "'Sansita Swashed', cursive",
        monospace: "'Roboto', sans-serif",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#222525',
        background: '#fff',
        grey: '#6c6c72',
        primary: '#ef523c',
        secondary: '#ffb851',
        muted: '#f8f5f2',
    },
}